import { html } from 'lit';
import { styles } from "./BmgFeatScannerDone.style";
import "@bmg-web/bmg-form/bmg-form.js";
import "@bmg-web/bmg-button/bmg-button.js";
import { goToScannerLogon } from "../../../util/navigate";
import { BmgFeatScannerElementBase } from "../../components/base/BmgFeatScannerElementBase";

export class BmgFeatScannerDone extends BmgFeatScannerElementBase {
  static get is() {
    return 'bmg-feat-scanner-done';
  }

  static get scopedElements() {
    return {};
  }

  static get properties() {
    return {
      levelOfAssurance: {
        type: Number,
        attribute: "level-of-assurance",
      }
    };
  }

  constructor() {
    super();
  }

  static get styles() {
    return styles;
  }

  _handleScannerLogon() {
    goToScannerLogon();
  }

  render() {
    return html`
      <div class="headline">And you're done</div>
      <div class="step-title">
          6. Complete activation and log on
      </div>
      <div class="step-note-text">
        Your scanner and PIN are the keys to your account; keeping them safe is your responsibility.
      </div>
      
      ${ this._renderUpliftInstructions() }
      
      <bmg-form id="bmgForm" name="form">
        <bmg-button class="button" @click=${ this._handleScannerLogon }>Log on with your scanner</bmg-button>
      </bmg-form>
    `;
  }

  _renderUpliftInstructions() {
    if (this.levelOfAssurance === 2) {
      return undefined; // No extra uplift required
    }

    return html`
      <div class="step-note-header">Authorized to approve items?</div>
      <div class="step-note-text">
        Note that if you're authorized to approve items, your coordinator will need to verify your
        identity. They have received a request to do this. After that, you'll be able to approve.
      </div>
    `;
  }
}
