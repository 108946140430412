import { html } from 'lit';
import { BmgButton } from "@bmg-web/bmg-button/lib/BmgButton";
import "../../../components/help-dialog/bmg-feat-scanner-help-dialog.js";
import { styles } from "./BmgFeatScannerStatusAlert.style";
import { BmgFeatScannerElementBase } from "../../../components/base/BmgFeatScannerElementBase";
import { activationHelpDialogModel } from "../../../model/help-dialog-model";
import { AmwaBackToLoginLink } from "../../../../components/amwa-back-to-login-link";
import { ScannerStatus } from "../../../model/cronto-type";

export const SCANNER_ALREADY_ACTIVATED_EVENT = 'bmg-feat-scanner-already-activated';
export const SCANNER_MISSING_EVENT = 'bmg-feat-scanner-missing';

export class BmgFeatScannerStatusAlert extends BmgFeatScannerElementBase {
  static get is() {
    return 'bmg-feat-scanner-status-alert';
  }

  static get scopedElements() {
    return {
      "bmg-button": BmgButton,
      "amwa-back-to-login-link": AmwaBackToLoginLink,
    };
  }

  static get properties() {
    return {
      scannerStatus: {
        type: String,
        attribute: 'scanner-status'
      }
    };
  }

  constructor() {
    super();
    this.scannerStatus = '';
  }


  static get styles() {
    return styles;
  }

  get _helpDialogElement() {
    return this.shadowRoot.querySelector('#helpDialog') || {};
  }

  _openHelpDialog() {
    this._helpDialogElement.open();
  }

  _renderScannerStatusTitle() {
    if (ScannerStatus.MISSING === this.scannerStatus) {
      return html`
          <div class="headline">Activate your scanner</div>
          <div class="step-title">You do not have a scanner</div>
      `;
    } else if (ScannerStatus.EFFECTIVE === this.scannerStatus) {
      return html`
          <div class="headline">Activate your scanner</div>
          <div class="step-title">You already have an active scanner</div>
      `;
    }
  }

  _renderScannerStatusAlert() {
    if (ScannerStatus.MISSING === this.scannerStatus) {
      return html`
          <bmg-alert id="alert-scanner-already-activated" type="warning">
              According to our administration, you do not
              currently have a scanner. If you believe this to be an error,please
              contact your coordinator and/or administration@mendesgans.nl
          </bmg-alert>
      `;
    } else if (ScannerStatus.EFFECTIVE === this.scannerStatus) {
      return html`
          <bmg-alert id="alert-scanner-already-activated" type="warning">
              According to our administration, you already have
              an active scanner. You can only have one active
              scanner at a time. This means you cannot
              continue this activation.
          </bmg-alert>
      `;
    }
  }

  render() {
    return html`
        ${ this._renderScannerStatusTitle() }
        <div class="form-container">
            ${ this._renderScannerStatusAlert() }
          <p>
            <div class="link-paragraph">Lost your scanner and in need of new one?</div>
            Report the incident to your coordinator and to your BMG
            serviceteam via
            <a class="mail-link" href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a>.
          </p>
          
          <p>
          <div class="link-paragraph">Not sure which scanner is yours?</div>
            Then maybe that was the reason you tried to activate a new
            one. If so, please find out which scanner is yours. Ask your
            coordinator or BMG service team to check in Megabank
            which serial number belongs to your account. Please
            contact your coordinator or BMG service team via
            <a class="mail-link" href="mailto:administration@mendesgans.nl">administration@mendesgans.nl</a>.
          </p>
          <bmg-button class="right" flat inverted @click=${this._openHelpDialog}>Need help?</bmg-button>
          <amwa-back-to-login-link></amwa-back-to-login-link>
          <bmg-feat-scanner-help-dialog id="helpDialog" .model=${ activationHelpDialogModel }></bmg-feat-scanner-help-dialog>
        </div>
    `;
  }
}