import { html } from 'lit';
import { styles } from "./BmgFeatScannerDoneYesNext.style";
import "@bmg-web/bmg-form/bmg-form.js";
import "@bmg-web/bmg-button/bmg-button.js";
import "../../../components/help-dialog/bmg-feat-scanner-help-dialog.js";
import { BmgFeatScannerDeviceImage } from "../../../util/device-image/BmgFeatScannerDeviceImage";
import { BmgFeatScannerElementBase } from "../../../components/base/BmgFeatScannerElementBase";
import { activationHelpDialogModel } from "../../../model/help-dialog-model";

export class BmgFeatScannerDoneYesNext extends BmgFeatScannerElementBase {
  static get is() {
    return 'bmg-feat-scanner-done-yes-next';
  }

  static get scopedElements() {
    return {
      'bmg-feat-scanner-device-image': BmgFeatScannerDeviceImage
    };
  }

  static get properties() {
    return {};
  }

  constructor() {
    super();
  }

  static get styles() {
    return styles;
  }

  _handleNext() {
    this._clearTechnicalError();
    this.dispatchEvent(new CustomEvent(BmgFeatScannerDoneYesNext.is + '-success', {
      bubbles: true,
      composed: true,
    }));
  }

  _restartActivation(e) {
    if (e) e.preventDefault();
  }

  get _helpDialogElement() {
    return this.shadowRoot.querySelector('#helpDialog') || {};
  }

  _openHelpDialog() {
    this._helpDialogElement.open();
  }

  render() {
    return html`
        <div class="headline">Activate your scanner</div>
        <div class="step-title">Your PIN code is set!</div>
        <bmg-form id="bmgForm" name="form">
          <ul class="info-bullets">
            <li>
              Choose \`Done\`, \`YES\` and \`NEXT\` on your scanner.
            </li>
          </ul>
          <div class="image-row">
            <bmg-feat-scanner-device-image
                class="img-responsive"
                view="response_code_activate"
            ></bmg-feat-scanner-device-image>
            <bmg-feat-scanner-device-image
                class="img-responsive"
                view="was_code_accepted"
            ></bmg-feat-scanner-device-image>
          </div>
          <bmg-button class="button" @click=${ this._handleNext }>Next</bmg-button>
          <bmg-button class="right" flat inverted @click=${ this._openHelpDialog }>Need help?</bmg-button>
          <bmg-feat-scanner-help-dialog id="helpDialog" .model=${ activationHelpDialogModel }></bmg-feat-scanner-help-dialog>
        </bmg-form>
    `;
  }
}