import { html } from 'lit';
import { BmgForm } from "@bmg-web/bmg-form/lib/BmgForm";
import { BmgButton } from "@bmg-web/bmg-button/lib/BmgButton";
import "../../components/help-dialog/bmg-feat-scanner-help-dialog.js";
import { styles } from "./BmgFeatScannerUserIdAndEmail.style";
import { ScannerService } from "../../services/scanner.service";
import { BmgFeatScannerElementBase, SUCCESS } from "../../components/base/BmgFeatScannerElementBase";
import { activationHelpDialogModel } from "../../model/help-dialog-model";
import { AmwaInput } from "../../../components/amwa-input/AmwaInput";

const EMAIL_VALIDATION_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const USERID_VALIDATION_REGEX =  /^[^\s]{1,16}$/;
const INVALID_USERID_ERROR_MESSAGE = "Please enter a valid user ID";
const INVALID_EMAIL_ADDRESS_MESSAGE = "Please enter a valid email address";
const INVALID_USERID_EMAIL_ERROR_MESSAGE = "This combination of user ID and email address is not valid. Please check both and try again.";

export class BmgFeatScannerUserIdAndEmail extends BmgFeatScannerElementBase {
  static get is() {
    return 'bmg-feat-scanner-user-id-and-email';
  }

  static get scopedElements() {
    return {
      "bmg-form": BmgForm,
      "amwa-input": AmwaInput,
      "bmg-button": BmgButton,
    };
  }

  static get properties() {
    return {
      _activationProgress: {
        type: Boolean,
        attribute: false
      }
    };
  }

  constructor() {
    super();
    this._activationProgress = false;
  }

  static get styles() {
    return styles;
  }

  get _userIdElement() {
    return this.shadowRoot.querySelector('#inputUserId') || {};
  }

  get _emailElement() {
    return this.shadowRoot.querySelector('#inputEmail') || {};
  }

  get _helpDialogElement() {
    return this.shadowRoot.querySelector('#helpDialog') || {};
  }

  _getAnonymizedEmail(email) {
    const ALLOWABLE_PUBLIC_USER_LENGTH = 3;
    const ANONYMIZER = "***";
    const [user, domain] = email.split("@");
    const publicUser = user.slice(0, -ANONYMIZER.length).slice(0, ALLOWABLE_PUBLIC_USER_LENGTH);
    return publicUser + ANONYMIZER + "@" + domain;
  }

  _resetValidationErrors() {
    this._userIdElement.errorMessage = INVALID_USERID_ERROR_MESSAGE;
    this._emailElement.errorMessage = INVALID_EMAIL_ADDRESS_MESSAGE;
  }

  _validateUserId(value) {
    const isValidUserIdFormat = USERID_VALIDATION_REGEX.test(value);
    if (!isValidUserIdFormat) {
      this._userIdElement.invalid = true;
      this._userIdElement.errorMessage = INVALID_USERID_ERROR_MESSAGE;
      return false;
    }
    return true;
  }

  _validateEmail(value) {
    const isValidEmailFormat = EMAIL_VALIDATION_REGEX.test(value);
    if (!isValidEmailFormat) {
      this._emailElement.invalid = true;
      this._emailElement.errorMessage = INVALID_EMAIL_ADDRESS_MESSAGE;
      return false;
    }
    return true;
  }

  _handleNext() {
    this._clearTechnicalError();
    this._resetValidationErrors();
    const userId = this._userIdElement.value;
    const email = this._emailElement.value;
    const isUserIdValid = this._validateUserId(userId);
    const isEmailValid = this._validateEmail(email);
    if (isUserIdValid && isEmailValid) {
      this._activationProgress = true;
      this._handleRequestOtp(userId, email);
    } else {
      console.error("Validation failed!");
    }
  }

  _handleRequestOtp(userName, emailAddress) {
    const otpRequest = { userName, emailAddress };
    ScannerService.requestOtp( otpRequest )
        .then(() => {
          this.fireCustomEvent( BmgFeatScannerUserIdAndEmail.is + SUCCESS, { userName, anonymizedEmail: this._getAnonymizedEmail(emailAddress) });        })
        .catch((error) => {
          this._handleOtpResponseError(error);
        });
  }

  _handleOtpResponseError(error) {
    this._activationProgress = false;

    if (error?.response?.status === 400) {
      this._userIdElement.invalid = true;
      this._userIdElement.errorMessage = INVALID_USERID_EMAIL_ERROR_MESSAGE;
      this._emailElement.invalid = true;
      this._emailElement.errorMessage = INVALID_USERID_EMAIL_ERROR_MESSAGE;
    } else {
      this.handleError( BmgFeatScannerUserIdAndEmail.is, error );
    }
  }

  _openHelpDialog() {
    this._helpDialogElement.open();
  }

  render() {
    return html`
        <div class="headline">Activate your scanner</div>
        <div class="step-title">1. Enter your user ID and email</div>
        <bmg-form id="bmgForm" name="form">
            <div class="form-container">
                <amwa-input
                        id="inputUserId"
                        name="userId"
                        label="User ID"
                        autofocus
                        maxLength="16"
                ></amwa-input>
                <amwa-input
                        id="inputEmail"
                        type="email"
                        name="email"
                        label="Email"
                        maxLength="100"
                ></amwa-input>
            </div>
            <bmg-button class="button" .disabled="${ this._activationProgress }" @click=${ this._handleNext }>Next</bmg-button>
            <bmg-button class="right" flat inverted @click=${ this._openHelpDialog }>Need help?</bmg-button>
            <bmg-feat-scanner-help-dialog id="helpDialog" .model=${ activationHelpDialogModel }></bmg-feat-scanner-help-dialog>
        </bmg-form>
    `;
  }
}