import { html } from 'lit';
import { styles } from './BmgFeatScannerActivation.style.js';
import { BmgCrontoOtpMeans } from "../../components/authentication/bmg-cronto-otp-means";
import { BmgFeatScannerOverview } from "./overview/BmgFeatScannerOverview";
import {
  BmgFeatScannerUserIdAndEmail
} from "./step-1-user-id-and-email/BmgFeatScannerUserIdAndEmail";
import { BmgFeatScannerLoginWithOtp } from "./step-2-login-with-otp/BmgFeatScannerLoginWithOtp";
import { BmgFeatScannerSerialNumber } from "./step-3-serial-number/BmgFeatScannerSerialNumber";
import { BmgFeatScannerSetupPin } from "./step-4-setup-pin/BmgFeatScannerSetupPin";
import { BmgFeatScannerDoneYesNext } from "./step-4-setup-pin/pin-is-set-done-yes-next/BmgFeatScannerDoneYesNext";
import { BmgFeatScannerDone } from "./step-6-done/BmgFeatScannerDone";
import { BmgFeatScannerFinishSetup } from "./step-5-finish-set-up-scanner/BmgFeatScannerFinishSetup";
import {
  goToError,
  redirectToSessionExpired
} from "../../util/navigate";
import {
  BmgFeatScannerElementBase,
  GENERIC_EXCEPTION_EVENT_ID,
  SUCCESS
} from "../components/base/BmgFeatScannerElementBase";
import {
  ONE_SECOND,
  PSD2_INACTIVITY_TIMEOUT_EVENT_ID,
  SESSION_TIMEOUT_EVENT_ID,
  SETUP_PSD2_INACTIVITY_HANDLERS
} from "../../components/authentication/psd2-inactivity-warning/Psd2InactivityWarning";
import { logout, PSD2_INACTIVITY_TIMEOUT } from "@ing-web/token-manager";
import {
  BmgFeatScannerStatusAlert, SCANNER_MISSING_EVENT,
  SCANNER_ALREADY_ACTIVATED_EVENT
} from "./step-3-serial-number/scanner-status-alert/BmgFeatScannerStatusAlert";
import { LogTraceInterceptor } from "../../ajax/log-trace-interceptor";
import {
  BmgFeatScannerMeansAgreement,
  MEANS_AGREEMENT_SUCCESS
} from "./step-3-serial-number/scanner-means-agreement/BmgFeatScannerMeansAgreement";
import { SHOW_AMWA_BACK_TO_LOGIN_LINK } from "../../pages/activate-scanner-page";
import { SessionService } from "../services/session.service";
import { ScannerStatus } from "../model/cronto-type";


export const MEANS_COMPONENTS = [
  { componentName: BmgCrontoOtpMeans.is },
];
export const MEANS_CLASS_MAP = {
  [ BmgCrontoOtpMeans.is ]: () => Promise.resolve(BmgCrontoOtpMeans),
};

const START_STEP = BmgFeatScannerOverview.is;

export class BmgFeatScannerActivation extends BmgFeatScannerElementBase {
  static get is() {
    return 'bmg-feat-scanner-activation';
  }

  static get scopedElements() {
    return {
      [BmgFeatScannerOverview.is]:         BmgFeatScannerOverview,
      [BmgFeatScannerUserIdAndEmail.is]:   BmgFeatScannerUserIdAndEmail,
      [BmgFeatScannerLoginWithOtp.is]:     BmgFeatScannerLoginWithOtp,
      [BmgFeatScannerSerialNumber.is]:     BmgFeatScannerSerialNumber,
      [BmgFeatScannerSetupPin.is]:         BmgFeatScannerSetupPin,
      [BmgFeatScannerDoneYesNext.is]:      BmgFeatScannerDoneYesNext,
      [BmgFeatScannerFinishSetup.is]:      BmgFeatScannerFinishSetup,
      [BmgFeatScannerDone.is]:             BmgFeatScannerDone,
      [BmgFeatScannerStatusAlert.is]:      BmgFeatScannerStatusAlert,
    };
  }

  static get properties() {
    return {
      _currentStep: {
        type: String,
        state: true,
      },
      _userId: {
        type: String,
        attribute: 'user-id',
      },
      _anonymizedEmail: {
        type: String,
        state: true,
      },
      _serialNumber: {
        type: String,
        attribute: 'serial-number'
      },
      _encryptedState: {
        type: String,
        attribute: 'encrypted-state'
      },
      _levelOfAssurance: {
        type: String,
        state: true,
      },
      _scannerStatus: {
        type: String,
        state: true,
      }
    };
  }

  static get styles() {
    return styles;
  }

  constructor() {
    super();
    this._boundBeforeUnloadEventHandler = this._beforeUnloadEventHandler.bind(this);
    this._boundAfterUnloadDialogClosed = this._afterUnloadDialogClosed.bind(this);
  }

  _registerUserName(userName) {
    this._userId = userName;
    LogTraceInterceptor.setUsername( userName );
  }

  _initCurrentStep() {
    this._currentStep = START_STEP;
  }

  _beforeUnloadEventHandler(event) {
    event.preventDefault();
    const dialogOpenTimestamp = Date.now();
    // handler will fire right after modal Unload Dialog is closed
    setTimeout(() => this._boundAfterUnloadDialogClosed( dialogOpenTimestamp ), 1);
    return (event.returnValue = "");
  }

  _afterUnloadDialogClosed(dialogOpenTimestamp) {
    const elapsedTime = Date.now() - dialogOpenTimestamp;
    if (elapsedTime > PSD2_INACTIVITY_TIMEOUT * ONE_SECOND) {
      this.fireCustomEvent( PSD2_INACTIVITY_TIMEOUT_EVENT_ID );
    }
  }

  _enableWarningOnLeavingThePage() {
    window.addEventListener('beforeunload', this._boundBeforeUnloadEventHandler, { capture: true });
  }

  _disableWarningOnLeavingThePage() {
    window.removeEventListener('beforeunload', this._boundBeforeUnloadEventHandler, { capture: true });
  }

  connectedCallback() {
    super.connectedCallback();
    this._initCurrentStep();
    logout();
    LogTraceInterceptor.clearUsername();
    this._startEventListeners();
    this._enableWarningOnLeavingThePage();
    this._fireContentChangeEvent();
  }

  disconnectedCallback() {
    this._disableWarningOnLeavingThePage();
    LogTraceInterceptor.clearUsername();
    logout();
    super.disconnectedCallback();
  }

  _startEventListeners() {
    this.addEventListener(BmgFeatScannerOverview.is + SUCCESS, () => {
      this._goToStep(BmgFeatScannerUserIdAndEmail.is);
    });

    this.addEventListener(BmgFeatScannerUserIdAndEmail.is + SUCCESS, (event) => {
      this._registerUserName( event.detail.userName );
      this._anonymizedEmail = event.detail.anonymizedEmail;
      this._goToStep(BmgFeatScannerLoginWithOtp.is);
    });

    this.addEventListener(BmgFeatScannerLoginWithOtp.is + SUCCESS, () => {
      this.fireCustomEvent(SETUP_PSD2_INACTIVITY_HANDLERS);
      this.fireCustomEvent(SHOW_AMWA_BACK_TO_LOGIN_LINK, { showAmwaBackToLoginLink: false })
      this._goToStep(BmgFeatScannerMeansAgreement.is);
    });

    this.addEventListener(MEANS_AGREEMENT_SUCCESS, () => {
      this.fireCustomEvent(SHOW_AMWA_BACK_TO_LOGIN_LINK, { showAmwaBackToLoginLink: true })
      this._goToStep(BmgFeatScannerSerialNumber.is);
    });

    this.addEventListener(BmgFeatScannerSerialNumber.is + SUCCESS, (event) => {
      this._serialNumber = event.detail.serialNumber;
      this._encryptedState = event.detail.encryptedState;
      this._goToStep(BmgFeatScannerSetupPin.is);
    });

    this.addEventListener(BmgFeatScannerSetupPin.is + SUCCESS, (event) => {
      this._encryptedState = event.detail.encryptedState;
      this._goToStep(BmgFeatScannerDoneYesNext.is);
    });

    this.addEventListener(BmgFeatScannerDoneYesNext.is + SUCCESS, () => {
      this._goToStep(BmgFeatScannerFinishSetup.is);
    });

    this.addEventListener(BmgFeatScannerFinishSetup.is + SUCCESS, async (event) => {
      this._levelOfAssurance = event.detail.levelOfAssurance;
      this._disableWarningOnLeavingThePage();
      await SessionService.safeLogoutSsoSession();
      this._goToStep(BmgFeatScannerDone.is);
    });

    this.addEventListener(SCANNER_ALREADY_ACTIVATED_EVENT, () => {
      this._scannerStatus = ScannerStatus.EFFECTIVE;
      this._disableWarningOnLeavingThePage();
      this._goToStep(BmgFeatScannerStatusAlert.is);
    });

    this.addEventListener(SCANNER_MISSING_EVENT, () => {
      this._scannerStatus = ScannerStatus.MISSING;
      this._disableWarningOnLeavingThePage();
      this._goToStep(BmgFeatScannerStatusAlert.is);
    });

    this.addEventListener('content-changed', this._fireContentChangeEvent.bind(this));

    this.addEventListener(GENERIC_EXCEPTION_EVENT_ID, async () => {
      this._disableWarningOnLeavingThePage();
      await LogTraceInterceptor.pendingTraceErrorCalls();
      goToError();
    });

    window.addEventListener(SESSION_TIMEOUT_EVENT_ID, () =>{
      this._disableWarningOnLeavingThePage();
      redirectToSessionExpired();
    });
  }

  render() {
    switch (this._currentStep) {
      case BmgFeatScannerOverview.is:
        return html`
          <bmg-feat-scanner-overview></bmg-feat-scanner-overview>
        `;

      case BmgFeatScannerUserIdAndEmail.is:
        return html`
            <bmg-feat-scanner-user-id-and-email></bmg-feat-scanner-user-id-and-email>
        `;

      case BmgFeatScannerLoginWithOtp.is:
        return html`
            <bmg-feat-scanner-login-with-otp
                    user-id="${ this._userId }"
                    anonymized-email="${ this._anonymizedEmail }"
            ></bmg-feat-scanner-login-with-otp>
        `;

      case BmgFeatScannerSerialNumber.is:
        return html`
            <bmg-feat-scanner-serial-number
                    user-id="${ this._userId }"
            ></bmg-feat-scanner-serial-number>
        `;

      case BmgFeatScannerSetupPin.is:
        return html`
            <bmg-feat-scanner-setup-pin
                    user-id="${ this._userId }"
                    .serialNumber="${ this._serialNumber }"
                    .encryptedState="${ this._encryptedState }"
            ></bmg-feat-scanner-setup-pin>
        `;

      case BmgFeatScannerDoneYesNext.is:
        return html`
            <bmg-feat-scanner-done-yes-next
                    user-id="${ this._userId }"
            ></bmg-feat-scanner-done-yes-next>
        `;

      case BmgFeatScannerFinishSetup.is:
        return html`
          <bmg-feat-scanner-finish-setup
              encrypted-state="${ this._encryptedState }"
              user-id="${ this._userId }"
          ></bmg-feat-scanner-finish-setup>
        `;

      case BmgFeatScannerDone.is:
        return html`
          <bmg-feat-scanner-done
              level-of-assurance="${ this._levelOfAssurance }"
          ></bmg-feat-scanner-done>
        `;

      case BmgFeatScannerStatusAlert.is:
        return html`
          <bmg-feat-scanner-status-alert
                  scanner-status="${ this._scannerStatus }"
          ></bmg-feat-scanner-status-alert>
        `;

      case BmgFeatScannerMeansAgreement.is:
        return html`
          <bmg-feat-scanner-means-agreement
              user-id="${ this._userId }"
          ></bmg-feat-scanner-means-agreement>
        `;

      default:
        throw new Error("Unknown step definition: " + this._currentStep);
    }
  }

  _goToStep(step) {
    this._currentStep = step;
    // Fire a content change event to notify change for dialog's to refit position according
    // to content.
    this._fireContentChangeEvent();
    window.setTimeout(() => {
      this.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    });
  }

  /**
   * Fires an event when content has been updated.
   *
   * @event ing-feat-scanner-content-changed
   * @private
   */
  _fireContentChangeEvent() {
    window.setTimeout(() => {
      this.dispatchEvent(new CustomEvent('ing-orange-cronto-hardware-token-content-changed', {
        bubbles: true,
        composed: true,
      }));
    });
  }
}
