import { html } from 'lit';
import { styles } from "./BmgFeatScannerOverview.style";
import "@bmg-web/bmg-form/bmg-form.js";
import "@bmg-web/bmg-button/bmg-button.js";
import { BmgFeatScannerElementBase, SUCCESS } from "../../components/base/BmgFeatScannerElementBase";
import { activationHelpDialogModel } from "../../model/help-dialog-model";


export class BmgFeatScannerOverview extends BmgFeatScannerElementBase {
  static get is() {
    return 'bmg-feat-scanner-overview';
  }

  static get scopedElements() {
    return {};
  }

  static get properties() {
    return {};
  }

  constructor() {
    super();
  }

  static get styles() {
    return styles;
  }

  get _helpDialogElement() {
    return this.shadowRoot.querySelector('#helpDialog') || {};
  }

  _handleNext() {
    this.fireCustomEvent( BmgFeatScannerOverview.is + SUCCESS );
  }

  _openHelpDialog() {
    this._helpDialogElement.open();
  }

  render() {
    return html`
        <div class="headline">Activate your scanner</div>
        <div class="step-title">In 6 steps</div>
            <div class="form-container">
              <div class="stepper">
                <div class="step">
                  <div class="circle"></div>
                  <div class="stepname">1. Enter your user ID and email</div>
                </div>
                <div class="sideLineInitial"></div>
                <div class="step">
                   <div class="circle"></div>
                    <div class="stepname">2. Confirm your email</div>
                </div>
                <div class="sideLineInitial"></div>
                <div class="step">
                  <div class="circle"></div>
                  <div class="stepname">3. Connect your scanner</div>
                </div>

                <div class="sideLineInitial"></div>
                <div class="step">
                  <div class="circle"></div>
                  <div class="stepname">4. Choose a PIN</div>
                </div>

                <div class="sideLineInitial"></div>
                <div class="step">
                  <div class="circle"></div>
                  <div class="stepname">5. Finish setting up your scanner</div>
                </div>

                <div class="sideLineInitial"></div>
                <div class="step">
                  <div class="circle"></div>
                  <div class="stepname">6. Complete activation and log on</div>
                </div>
              </div>
            </div>
          <bmg-button class="button" @click=${ this._handleNext }>Start activation</bmg-button>
          <bmg-button class="right" flat inverted @click=${ this._openHelpDialog }>Need help?</bmg-button>
          <bmg-feat-scanner-help-dialog id="helpDialog" .model=${ activationHelpDialogModel }></bmg-feat-scanner-help-dialog>
    `;
  }
}