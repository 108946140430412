import { amwaApplicationStyle } from "../../../amwa-application-style";
import { css, unsafeCSS } from 'lit';
import { bmgBlue, bmgBlue18, fontRegular, fontSizeMl } from "@bmg-web/bmg-styles";

export const styles = css`
  ${ amwaApplicationStyle }

  .form-container {
    background-color: ${unsafeCSS(bmgBlue18)};
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 16px;
  }

  .step-title {
    ${unsafeCSS(fontSizeMl)}
    ${unsafeCSS(fontRegular)}
    color: ${unsafeCSS(bmgBlue)};
  }

  .button {
    margin-bottom: 16px;
  }
`;
