import { amwaApplicationStyle } from "../../../amwa-application-style";
import { css, unsafeCSS } from 'lit';
import { bmgBlack87, bmgBlue, bmgBlue18, fontBmd, fontRegular, fontRmd, fontSizeMl } from "@bmg-web/bmg-styles";

export const styles = css`
  ${ amwaApplicationStyle }

  .form-container {
    background-color: ${unsafeCSS(bmgBlue18)};
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 16px;
  } 
  
   .step-title {
    ${ unsafeCSS(fontSizeMl) }
    ${ unsafeCSS(fontRegular) }
    color: ${ unsafeCSS(bmgBlue) };
    margin-bottom: 16px;
  }

  .step-note-header {
    ${ unsafeCSS(fontBmd) }
    color: ${ unsafeCSS(bmgBlack87) };
  }

  .step-note-text {
    ${ unsafeCSS(fontRmd) }
    color: ${ unsafeCSS(bmgBlack87) };
    margin-bottom: 16px;
  }

  .button {
    margin-bottom: 16px;
  }
`;
