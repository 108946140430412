import { html } from 'lit';
import { styles } from './BmgFeatScannerLogonUserId.style.js';
import { BmgFeatScannerElementBase, SUCCESS } from "../../components/base/BmgFeatScannerElementBase";
import { BmgForm } from "@bmg-web/bmg-form/lib/BmgForm";
import { BmgButton } from "@bmg-web/bmg-button/lib/BmgButton";
import { BmgCheckbox } from "@bmg-web/bmg-checkbox";
import { logonHelpDialogModel } from "../../model/help-dialog-model";
import "../../components/help-dialog/bmg-feat-scanner-help-dialog";
import { COOKIE_KEY, deleteCookie, getCookieValue, setCookie } from "../../../util/cookie-service";
import { AmwaInput } from "../../../components/amwa-input/AmwaInput";

const USERID_VALIDATION_REGEX =  /^[^\s]{8,16}$/;
const INVALID_USERID_ERROR_MESSAGE = "Please enter a valid user ID of at least 8 characters";

export class BmgFeatScannerLogonUserId extends BmgFeatScannerElementBase {

  static get is() {
    return 'bmg-feat-scanner-logon-user-id';
  }

  static get scopedElements() {
    return {
      "bmg-form": BmgForm,
      "amwa-input": AmwaInput,
      "bmg-button": BmgButton,
      "bmg-checkbox": BmgCheckbox
    };
  }

  static get properties() {
    return {
      userId: {
        type: String,
        attribute: "user-id"
      },
      userIdCookie: { type: String },
      rememberUserId: { type: Boolean },
    };
  }

  static get styles() {
    return styles;
  }

  constructor() {
    super();
    this.userIdCookie = getCookieValue(COOKIE_KEY.REMEMBER_USER_ID);
    this.rememberUserId = !!this.userIdCookie;
  }

  get _userIdElement() {
    return this.shadowRoot.querySelector('#inputUserId') || {};
  }

  get _helpDialogElement() {
    return this.shadowRoot.querySelector('#helpDialog') || {};
  }

  _validateUserId(value) {
    const isValidUserIdFormat = USERID_VALIDATION_REGEX.test(value);
    if (!isValidUserIdFormat) {
      this._userIdElement.invalid = true;
      this._userIdElement.errorMessage = INVALID_USERID_ERROR_MESSAGE;
      return false;
    }
    return true;
  }

  _handleNext() {
    this._clearTechnicalError();
    const userId = this._userIdElement.value;
    const isUserIdValid = this._validateUserId(userId);
    if (!isUserIdValid) {
      console.error("Validation failed!");
      return;
    }

    this._handleRememberUserId(userId);

    this.fireCustomEvent( BmgFeatScannerLogonUserId.is + SUCCESS, {
      userId: userId,
    });
  }

  _toggleRememberUserId() {
    this.rememberUserId = !this.rememberUserId;
  }

  _handleRememberUserId(userId) {
    if (this.rememberUserId) {
      setCookie(COOKIE_KEY.REMEMBER_USER_ID, userId);
    } else {
      deleteCookie(COOKIE_KEY.REMEMBER_USER_ID);
    }
  }

  _openHelpDialog() {
    this._helpDialogElement.open();
  }

  render() {
    return html`
      <div class="headline">Log on with your scanner</div>
      <div class="bmg-scanner-icon"></div>
      <bmg-form id="bmgForm" name="form">
        <div class="form-container">
          <amwa-input
              id="inputUserId"
              name="userId"
              label="User ID"
              autofocus
              minlength="8"
              maxLength="16"
              errorMessage=""
              value="${ this.userIdCookie }"
          ></amwa-input>
          <bmg-checkbox id="rememberUserId" .checked=${ this.rememberUserId } @change=${ this._toggleRememberUserId }>
            <span class="remember-me">Remember me</span>
            <span class="remember-me-cookie">To remember your user ID, we use a 
              <a target="_blank" href="https://www.mendesgans.nl/privacy-and-cookies.aspx.html">cookie</a>
            </span>
          </bmg-checkbox>
        </div>
        <bmg-button class="button" @click=${ this._handleNext }>Next</bmg-button>
        <bmg-button class="right" flat inverted @click=${ this._openHelpDialog }>Need help?</bmg-button>
        <bmg-feat-scanner-help-dialog id="helpDialog" .model=${ logonHelpDialogModel }></bmg-feat-scanner-help-dialog>
      </bmg-form>
    `;
  }

}
