import { html } from 'lit';
import { styles } from "./BmgFeatScannerSetupPin.style";
import "@bmg-web/bmg-form/bmg-form.js";
import "@bmg-web/bmg-button/bmg-button.js";
import "../../components/cronto-image/bmg-feat-scanner-cronto-image.js";
import "../../components/help-dialog/bmg-feat-scanner-help-dialog.js";
import { crontoTypes, ENCRYPTED_STATE_HEADER_KEY } from "../../model/cronto-type";
import { CrontoHardwareTokenService } from "../../services/cronto-hardware-token.service";
import { SUCCESS } from "../../components/base/BmgFeatScannerElementBase";
import {
  BmgFeatScannerCrontoImage,
  CRONTO_IMAGE_EXPIRED_EVENT_ID
} from "../../components/cronto-image/BmgFeatScannerCrontoImage";
import { activationHelpDialogModel } from "../../model/help-dialog-model";
import { AmwaMaskedInput } from "../../../components/amwa-input/AmwaMaskedInput";
import { BmgFeatScannerPrefillable } from "../../components/base/BmgFeatScannerPrefillable";

const CONFIRMATION_CODE_VALIDATION_REGEX =  /^\d{15}$/;
const INVALID_CONFIRMATION_CODE_MESSAGE = "Please enter a valid confirmation code";

export class BmgFeatScannerSetupPin extends BmgFeatScannerPrefillable {
  static get is() {
    return 'bmg-feat-scanner-setup-pin';
  }

  static get scopedElements() {
    return {
      "amwa-masked-input": AmwaMaskedInput,
    };
  }

  static get properties() {
    return {
      _crontoParams: { type: Object },
      _crontoImageExpired: { type: Boolean },
      _activationProgress: {
        type: Boolean,
        attribute: false
      }
    };
  }

  constructor() {
    super();
    this._crontoImageExpired = false;
    this._activationProgress = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener(BmgFeatScannerCrontoImage.is + SUCCESS, this._handleCrontoImageAppear);
    this.addEventListener(CRONTO_IMAGE_EXPIRED_EVENT_ID, this._handleCrontoImageExpired);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener(BmgFeatScannerCrontoImage.is + SUCCESS, this._handleCrontoImageAppear);
    this.removeEventListener(CRONTO_IMAGE_EXPIRED_EVENT_ID, this._handleCrontoImageExpired);
  }

  _handleCrontoImageAppear = ({ detail }) => {
    this._crontoParams = detail;
    this._crontoImageExpired = false;
  }

  _handleCrontoImageExpired = () => {
    this._crontoImageExpired = true;
  }

  static get styles() {
    return styles;
  }

  get _confirmationCodeElement() {
    return this.shadowRoot.querySelector('#inputConfirmationCode') || {};
  }

  _validateConfirmationCode() {
    const confirmationCode = this._confirmationCodeElement.unmaskedValue;
    const isConfirmationCodeValid = CONFIRMATION_CODE_VALIDATION_REGEX.test(confirmationCode);
    if (!isConfirmationCodeValid) {
      this._confirmationCodeElement.invalid = true;
      this._confirmationCodeElement.errorMessage = INVALID_CONFIRMATION_CODE_MESSAGE;
      return false;
    }
    return true;
  }

  _handleNext() {
    this._clearTechnicalError();
    const confirmationCodeElement = this._confirmationCodeElement;
    const isConfirmationCodeValid = this._validateConfirmationCode();
    if (isConfirmationCodeValid) {
      this._activationProgress = true;
      this._handleSetupPin(confirmationCodeElement.unmaskedValue);
    } else {
      console.error("Validation error");
    }
  }

  _handleSetupPin(tokenResponse) {
    CrontoHardwareTokenService.requestScannerImage(
        crontoTypes.enrolDevice.response,
        {tokenResponse: tokenResponse},
        { 'x-ing-crontoencryptedstate': this._crontoParams.encryptedState || '' }, // TODO: should be handled by interceptor. see story #4552695
    ).then((response) => {
      this.fireCustomEvent(BmgFeatScannerSetupPin.is + '-success', {
        encryptedState: response.headers[ENCRYPTED_STATE_HEADER_KEY] // TODO: should be handled by interceptor. see story #4552695
      });
    })
    .catch((error) => this._handleSetupPinError(error));
  }

  _handleSetupPinError(error) {
    this._activationProgress = false;
    this.handleError(BmgFeatScannerSetupPin.is, error)
  }

  _renderCrontoImage() {
    return html`
        <bmg-feat-scanner-cronto-image
                cronto-type="enrolDevice"
                serial-number ="${ this.serialNumber }"
                encrypted-state="${ this.encryptedState }"
        ></bmg-feat-scanner-cronto-image>
    `;
  }

  get _helpDialogElement() {
    return this.shadowRoot.querySelector('#helpDialog') || {};
  }

  _openHelpDialog() {
    this._helpDialogElement.open();
  }

  render() {
    return html`
        <div class="headline">Activate your scanner</div>
        <div class="step-title">4. Choose a PIN</div>
        <bmg-form id="bmgForm" name="form">
            <ul class="info-bullets">
                <li>Once more, scan the color code below with your scanner.</li>
            </ul>

            ${ this._renderCrontoImage() }
            
            <ul class="info-bullets">
                <li>
                    Choose and confirm a PIN code on your scanner.
                    Your scanner will show a confirmation code.
                    Enter it below, then click 'Next'.
                </li>
                <li>
                    Do not write down or share your PIN code.
                </li>
            </ul>
            <div class="form-container">
                <amwa-masked-input
                        id="inputConfirmationCode"
                        name="confirmationCode"
                        label="Confirmation code"
                        autofocus
                        minlength="19"
                        maxLength="19"
                        mask="000 000 000 000 000"
                        ?disabled="${ this._crontoImageExpired }"
                ></amwa-masked-input>
            </div>
            <bmg-button class="button" @click=${ this._handleNext } .disabled="${ this._crontoImageExpired  || this._activationProgress }">Next</bmg-button>
            <bmg-button class="right" flat inverted @click=${ this._openHelpDialog }>Need help?</bmg-button>
            <bmg-feat-scanner-help-dialog id="helpDialog" .model=${ activationHelpDialogModel }></bmg-feat-scanner-help-dialog>
        </bmg-form>
    `;
  }
}